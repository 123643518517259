<template>
    <el-row v-loading="loading">
        <el-row class="">
            <table class="table table-hover table-responsive-lg table-bordered">
                <thead>
                  <tr>
                      <th class="text-center">Họ và tên</th>
                      <th class="text-center">Mã học viên</th>
                      <th class="text-center">Số điện thoại</th>
                      <th class="text-center">Trạng thái</th>
                      <th class="text-center">Hình thức chấm</th>
                      <th class="text-center">Thời gian GV chấm bài</th>
                      <th class="text-center">Hành động</th>
                  </tr>
                </thead>
                <tbody v-if="lessons.length > 0">
                    <tr v-for="(studentLesson,index) in lessons" :key="index">
                        <td>{{ studentLesson.name }}</td>
                        <td>{{ studentLesson.user_id + 100000 }}</td>
                        <td>{{ studentLesson.phone }}</td>
                        <td class="text-center">
                          <el-button size="small" type="warning" v-if="!studentLesson.test">Chưa làm bài tập</el-button>
                          <el-button size="small" type="primary"
                                     v-else-if="studentLesson.test && !studentLesson.test.status_mark && studentLesson.test.in_time">
                            Cần chấm bài
                          </el-button>
                          <el-button type="success" size="small"
                                     v-else-if="studentLesson.test && studentLesson.test.status_mark">
                            Đã chấm điểm
                          </el-button>
                          <el-button size="small" type="primary"
                                     v-if="studentLesson.test && !studentLesson.test.status_mark && !studentLesson.test.in_time">
                            Nộp bài muộn
                          </el-button>
                        </td>
                      <td class="text-center">
                          <span v-if="!studentLesson.test"></span>
                          <span v-else-if="studentLesson.test.has_handmade_mark_test">Chấm tự động & GV chấm</span>
                          <span v-else>Chấm tự động</span>
                      </td>
                      <td>
                          <span v-if="studentLesson.test && studentLesson.test.has_handmade_mark_test && studentLesson.test.updated_at != studentLesson.test.created_at && !studentLesson.test.homework_grader">
                              {{ studentLesson.test ? $moment(studentLesson.test.updated_at).format("H:mm:ss DD-MM-YYYY") : '' }}
                          </span>
                      </td>
                      <td>
                        <div class="d-flex justify-content-around h-100 w-100" v-if="studentLesson.test">
                          <el-button size="small"
                                     icon="fas fa-eye" type="primary"
                                     class="btn btn-sm btn-outline-info"
                                     title="Xem BTVN"
                                  @click="viewHomework(studentLesson)">
                          </el-button>
                          <el-button v-if="studentLesson.test && !studentLesson.test.status_mark" size="small"
                                     icon="fas fa-pencil-alt" type="warning"
                                     class="btn btn-sm btn-outline-info"
                                     title="Chấm điểm" :loading="disable_button"
                                  @click="showExamMark(studentLesson, 'mark', item.learning_schedule_id)">
                          </el-button>
                        </div>
                      </td>
                    </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="5"><h3 class="text-warning text-center">Chưa có dữ liệu</h3></td>
                  </tr>
                </tbody>
            </table>
        </el-row>
        <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close">Đóng</button>
        </span>
    </el-row>
</template>

<script>

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {EXAM_LIST_MARK, EXAM_MARK, CHECK_LAST_SUBMISSION } from "@/core/services/store/classes/classes.module";

const _ = deepdash(lodash);
export default {
    props: {
      item : {
        type: Object,
        default() {
          return {}
        }
      },
      learning_schedule_id : Number,
      lesssonNumber : Number
    },
    data() {
        return {
          lessons: [],
          loading: false,
          classroom_id: '',
          disable_button: false,
          count: '',
        }
    },
    created() {
      this.classroom_id = this.$route.params.id;
    },
    watch: {
      item: {
      handler() {
        this.loading = true;
        this.$store.dispatch(EXAM_LIST_MARK, {
          classroom_id: this.$route.params.id,
          lesson: this.item.lesson,
          type: 'homework'
        }).then((res) => {
          this.lessons = res.data;
        }).catch((res)=> {
          this.$message({
            type : 'error',
            message : 'Sever đang bận! ' + res.data.message,
            showClose: true
          });
        }).finally(() => { this.loading = false });

        this.$store.dispatch(CHECK_LAST_SUBMISSION, {
          classroom_id: this.$route.params.id,
          lesson: this.item.lesson,
          type: 'homework'
        }).then((res) => {
          this.count = res.data;
        }).catch((res)=> {
          this.$message({
            type : 'error',
            message : 'Sever đang bận! ' + res.data.message,
            showClose: true
          });
        }).finally(() => { this.loading = false });

      },
      immediate: true
    },
  },
    methods: {
        viewHomework(studentLesson){
          let classroom_id = this.$route.params.id;
            const routeData = this.$router.resolve({ name: "content-homework",params: {id : classroom_id},
            query: { classroom_id: classroom_id,learning_schedule_id:this.item.learning_schedule_id,student_id :
                 studentLesson.user_id, lesson: this.item.lesson , type:'view-result'}});
            window.open(routeData.href, '_blank');
        },
        close: function () {
          if (this.count) {
            this.$emit('dialogClose');
            return;
          }

          this.$emit('close');
        },
        showExamMark: function (studentLesson, typeShow, learning_schedule_id){
          this.disable_button = true;
          this.$store.dispatch(EXAM_MARK, {
            classroom_id: this.$route.params.id,
            course_student_id: studentLesson.course_student_id,
            overall_id: studentLesson.test.overall_id
          }).then((res) => {
            this.$emit('openHomeWorkExam', { homeworks: res.data, student: studentLesson,
              lesson: this.item.lesson, typeShow: typeShow ,
              learning_schedule_id: learning_schedule_id});
          }).catch((res)=> {
            this.$message({
              type : 'error',
              message : 'Sever đang bận! ' + res.data.message,
              showClose: true
            });
          }).finally(() => this.disable_button = false);
        }
    },
}
</script>
<style lang="scss" scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
}
</style>

