<template>
  <div>
    <ValidationObserver v-if="!studentGetGift || studentGetGift.length == 0" ref="form" v-slot="{ handleSubmit }">
      <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off" ref="form-edit">
        <div style="word-break: break-word">
          <el-radio-group class="w-100" v-model="radio">
            <el-radio :label="1">Tặng hộp quà may mắn cho 01 học viên học tập tốt nhất trong buổi học:</el-radio>
            <div>
              <div class="mt-3">
                <ValidationProvider vid="type" name="Chọn học viên" rules="required" v-slot="{ errors }">
                  <el-select :disabled="is_selected" clearable filterable class="w-100" v-model="student_id" placeholder="Chọn học viên">
                    <el-option v-for="item in listStudentAttendance" :key="item.id"
                      :label="item.id + 100000 + ' - ' + item.name" placeholder="Chọn học viên" :value="item.id">
                      <span style="float: left">{{
                        item.id + 100000 + " - " + item.name
                      }}</span>
                    </el-option>
                  </el-select>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{ errors[0] }}
                    </div>
                  </div>
                </ValidationProvider>
              </div>
            </div>
            <el-radio class="mt-3" :label="2">Không tặng quà</el-radio>
          </el-radio-group>
        </div>
        <br />
      </form>
    </ValidationObserver>
    <div v-else>
      <el-table :data="studentGetGift" stripe border class="table-hover mt-5" style="width: 100%">
        <el-table-column prop="student_name" label="Hành động">
        </el-table-column>
        <el-table-column prop="created_user" label="Người thực hiện">
        </el-table-column>
        <el-table-column prop="created_at" label="Thời gian thực hiện">
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
  
<script>
import lodash from "lodash-es";
import deepdash from "deepdash-es";
import { mapGetters } from "vuex";

const _ = deepdash(lodash);

export default {
  components: {},
  props: {
    item: {
      type: Object,
      default: () => {
        return null;
      },
    },
    listStudentAttendance: {
      type: Array,
      default: () => {
        return [];
      },
    },
    studentGetGift: {
      type: Array,
      default: () => {
        return [];
      },
    },
    dialogGiveGift: {
      type: Boolean,
      default: () => {
        return false;
      },
    },
  },
  data() {
    return {
      student_id: "",
      radio: 1,
      is_selected:false
    };
  },
  watch: {
    student_id: {
      immediate: true,
      handler(val) {
        let student_data = this.listStudentAttendance.find(
          (element) => element.id == val
        );
        this.$emit("select-student", student_data);
      },
    },
    dialogGiveGift: {
      immediate: true,
      handler(val) {
        this.student_id = '';
        this.radio = 1;
      },
    },
    radio : {
      handler(val) {
          this.student_id = '';
          this.is_selected = val == 2 ? true : false;
          this.$emit("radio", val);
      },
    }
  },
  mounted() {
  },
  computed: {
    ...mapGetters["currentUser"],
  },
  methods: {},
};
</script>
<style scoped></style>
