<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="form" @submit.prevent="handleSubmit(summit)" autocomplete="off" ref="form-edit">
      <div class="form-row">
        <div class="col-md-6 mb-3">
          <label class="form-label">Tên</label>
          <ValidationProvider vid="name" name="Tên" rules="required"
                              v-slot="{ errors, classes }">
            <input disabled type="text" class="form-control" placeholder="Tên"
                   autocomplete="false" v-model="tutoring.name"
                   :class="classes">
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6 mb-3">
          <label class="form-label">Hình thức học <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="type" name="Hình thức học" rules="required"
                              v-slot="{ errors, classes }">
            <el-select class="w-100" v-model="tutoring.study_type" placeholder="Chọn hình thức học" @change="changeStudyType">
              <el-option
                  :class="classes"
                  v-for="item in typeLearning"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6 mb-3" v-if="tutoring.study_type === 1">
          <ValidationProvider vid="zoom_id" name="Zoom ID" rules="required"
                              v-slot="{ errors, classes }">
            <label class="form-label">Zoom Id <span class="text-danger">(*)</span></label>
            <input type="text" class="form-control" placeholder="Zoom id"
                   autocomplete="false" v-model="tutoring.zoom_id" :class="classes" >
            <div class="fv-plugins-message-container">
              <div data-field="zoom_id" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-6 mb-3" v-if="tutoring.study_type === 1">
          <ValidationProvider vid="password" name="Zoom password" rules="required"
                              v-slot="{ errors, classes }">
            <label class="form-label">Password <span class="text-danger">(*)</span></label>
            <input type="text" class="form-control" placeholder="Password"
                   autocomplete="false" v-model="tutoring.password" :class="classes" >
            <div class="fv-plugins-message-container">
              <div data-field="password" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label">Học viên <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="course_students" name="Học viên" rules="required"
                              v-slot="{ errors, classes }">
            <el-select class="w-100" v-model="tutoring.course_students" multiple placeholder="Chọn học viên" >
              <el-option
                  :class="classes"
                  v-for="item in course_students"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label">Ngày học <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="date" name="Ngày học" rules="required"
                              v-slot="{ errors, classes }">
            <el-date-picker :class="classes"
                            class="w-100"
                            v-model="tutoring.date"
                            type="date"
                            placeholder="Thời gian học"
                            @change="getEmptyRooms"
                            value-format="yyyy-MM-dd"
            ></el-date-picker>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label">Thời gian bắt đầu học <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="start_time" name="Thời gian bắt đầu" rules="required"
                              v-slot="{ errors, classes }">
            <el-time-select
                :class="classes"
                class="w-100"
                v-model="tutoring.start_time"

                :picker-options="{ start: '08:30', step: '00:15', end: '22:30'}"
                placeholder="Thời gian bắt đầu"
                @change="getEmptyRooms"
            >
            </el-time-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-4 mb-3">
          <label class="form-label">Thời gian kết thúc <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="end_time" name="Thời gian kết thúc" rules="required"
                              v-slot="{ errors, classes }">
            <el-time-select
                :class="classes"
                class="w-100"
                v-model="tutoring.end_time"
                :picker-options="{ start: '08:30', step: '00:15', end: '22:30'}"
                placeholder="Thời gian kết thúc" @change="getEmptyRooms">
            </el-time-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-12 mb-3" v-if="tutoring.study_type === 0">
          <label class="form-label">Phòng học <span class="text-danger">(*)</span></label>
          <ValidationProvider vid="room" name="Phòng học" rules="required"
                              v-slot="{ errors, classes }">
            <el-select class="w-100" v-model="tutoring.room_id" placeholder="Chọn phòng học">
              <el-option
                  :class="classes"
                  v-for="item in rooms"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <div class="fv-plugins-message-container">
              <div data-field="name" data-validator="notEmpty"
                   class="fv-help-block">{{ errors[0] }}
              </div>
            </div>
          </ValidationProvider>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label">Nội dung bài học</label>
          <el-input
              type="textarea"
              placeholder="Nhập nội dung bài học"
              v-model="tutoring.content"
              show-word-limit
          >
          </el-input>
        </div>
        <div class="col-md-12 mb-3">
          <div class="row">
            <div class="col-md-6">
              <label class="form-label">Trợ giảng</label>
              <el-select class="w-100" v-model="tutoring.tro_giang" placeholder="Chọn trợ giảng" >
                <el-option
                    v-for="item in tutors"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                </el-option>
              </el-select>
            </div>
            <div class="col-md-6 text-center align-items-center">
              <el-checkbox v-model="tutoring.tro_giang_day_hoc" class="mt-5" :disabled="!tutoring.tro_giang">Trợ giảng dạy</el-checkbox>
            </div>
          </div>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label">Nội dung bài học</label>
          <el-input
              type="textarea"
              placeholder="Nhập nội dung bài học"
              v-model="tutoring.content"
              show-word-limit
          >
          </el-input>
        </div>
        <div class="col-md-12 mb-3">
          <label class="form-label">Ghi chú</label>
          <el-input
              type="textarea"
              placeholder="Nhập nội dung ghi chú"
              v-model="tutoring.note"
              show-word-limit
          >
          </el-input>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-3">
        <button class="btn btn-default mr-2" type="button" @click="close" >
          <i v-if="loading" class="el-icon-loading"></i> Đóng
        </button>
        <button class="btn btn-primary" type="submit" >
          <i v-if="loading" class="el-icon-loading"></i> {{ 'Sửa' }}
        </button>
      </div>
    </form>
  </ValidationObserver>
</template>


<script>
import {GET_DATA_EDIT_TUTORING, EDIT_TUTORING} from "@/core/services/store/classes/classes.module";
import {GET_DATA_CREATE_TUTORING, LIST_EMPTY_ROOMS} from "../../../core/services/store/classes/classes.module";

export default {
  name: "edit-tutoring",
  props: {
    tutoring_id: {
      type: Number,
      default: 0
    },
  },
  data() {
    return {
      typeLearning: [
        {
          value: 1,
          label: 'Học trực tuyến'
        },
        {
          value: 0,
          label: 'Học offline'
        }
      ],
      options: [],
      isDisable: false,
      tutoring: {
        name: '',
        classroom_id: this.$route.params.id,
        study_type: '',
        date: '',
        start_time: '',
        end_time: '',
        course_students: [],
        room_id: '',
        content: '',
        zoom_id: '',
        password: '',
        tro_giang: '',
        tro_giang_day_hoc: '',
        note: '',
      },
      course_students: [],
      tutors: [],
      rooms: [],
      maxTutoring: 0,
      loading: false,
      current_date: ''
    }
  },
  watch : {
    tutoring_id: {
      handler() {
        this.getDataUpdate();
        this.getDataCreate();
      },
      immediate: true
    },
  },
  methods: {
    getDataUpdate() {
      this.$store.dispatch(GET_DATA_EDIT_TUTORING, {
        id: this.tutoring_id
      }).then((res) => {
        let _this = this;
        if (!res.error) {
          this.tutoring = res.data;
          this.getEmptyRooms();
          this.current_date = this.tutoring.date;
          this.isDisable = new Date(this.tutoring.date) < new Date();
          const tags = ["input", "textarea", "select"];
          tags.forEach((tagName) => {
            const nodes = _this.$el.getElementsByTagName(tagName);
            for (let i = 0; i < nodes.length; i++) {
              nodes[i].disabled = 'disabled';
              nodes[i].tabIndex = -1;
            }
          });
        }
      });
    },
    getDataCreate() {
      this.$store.dispatch(GET_DATA_CREATE_TUTORING, {
        classroom_id: this.$route.params.id
      }).then((res) => {
        if (!res.error) {
          this.course_students = res.data.course_students;
          this.rooms = res.data.rooms;
          this.maxTutoring = res.data.maxTutoring;
          this.tutors = res.data.teaching_assistants;
          this.$emit('tutoringSuccess');
        }
      });
    },
    summit() {
      this.loading = true;
      if (new Date(this.tutoring.date) < new Date(this.current_date)){
        this.$refs.form.setErrors({date : 'Ngày học không được sửa về trước'});
        return;
      }
      this.$store.dispatch(EDIT_TUTORING, { id: this.tutoring_id, data: this.tutoring }).then((res) => {
        this.$message({
          type: 'success',
          message: 'Sửa buổi học phụ đạo thành công',
          showClose: true
        });
        this.close();
        this.loading = false;
      }).catch((e) => {
        this.$message({
          message: e.data.message,
          type: 'error'
        });
        this.loading = false;
      });
    },
    close: function () {
      this.$emit('close');
    },
    getEmptyRooms() {
      this.$store.dispatch(LIST_EMPTY_ROOMS, {
        tutoring_id : this.tutoring_id,
        classroom_id: this.$route.params.id,
        date: this.tutoring.date,
        start_time: this.tutoring.start_time,
        end_time: this.tutoring.end_time,
      }).then((res) => {
        this.tutoring.room = '';
        this.rooms = res.data;
      }).catch((res) => {
        this.$message({
          message: res.data.message,
          type: 'error'
        });
      })
    },
    changeStudyType() {
      if (this.tutoring.study_type === 1){
        this.tutoring.room_id = '';
      }else {
        this.tutoring.zoom_id = '';
        this.tutoring.password = '';
      }
    },
  },
}
</script>

<style scoped>

</style>
