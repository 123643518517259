<template>
  <el-row class="table-responsive-lg ">
    <div class="mb-4">
      <p class="bold">Hướng dẫn</p>
      <el-row>
        <table class="table table-bordered m-0">
          <thead>
            <tr>
              <th>Phân loại</th>
              <th>Nội dung</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in list_instruct" :key="index">
              <td>{{item.classify}}</td>
              <td>{{item.content}}</td>
            </tr>
          </tbody>
        </table>
      </el-row>
    </div>

      <p class="d-flex justify-content-end" v-if="time_comment"> Cập nhật lúc {{time_comment}}</p>
    <el-row class="table-responsive-lg">
      <p class="bold">Nhận xét học viên</p>
      <div class="table-wrapper-scroll-y my-custom-scrollbar">
        <table class="table table-bordered table-striped mb-0">
          <thead>
          <tr>
            <th> Họ và tên</th>
            <th> Mã học viên</th>
            <th class="text-center"> Nhận xét học viên</th>
          </tr>
          </thead>
          <tbody>
          <CommentItem
              v-for="(comment, index) in  comments" :key="index"
              :comment="comment"
              :optionClassify="option_classify"
              @changeComment="changeComment"
              @changeClassify="changeClassify"
          ></CommentItem>
          </tbody>
        </table>
      </div>
    </el-row>
    <div class="text-red">
      {{checkError}}
    </div>
<!--    dkien nhan xet-->
<!--    v-else-if="comments.length && !comments[0].user_comment_time"-->
      <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close">Đóng</button>
            <button class="btn btn-primary ml-2" @click="postCommentPhuDao" v-if="type_comment">Gửi nhận xét phụ đạo</button>
            <button class="btn btn-primary ml-2" @click="postComment" v-else-if="loadingPost && comments.length && !comments[0].user_comment_time">Gửi nhận xét</button>
        </span>
  </el-row>
</template>

<script>

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import CommentItem from "./components/CommentItem";
import {LIST_INSTRUCT, OPTION_CLASSIFY} from "@/core/services/store/classes/classes.module";

const _ = deepdash(lodash);

export default {
  components: {CommentItem},
  data() {
    return {
      list_instruct: LIST_INSTRUCT,
      option_classify: OPTION_CLASSIFY,
      loading: true
    }
  },
  props: {
    comments: {
      type: Array,
      default: () => {
        return [];
      }
    },
    type_comment: {
      type: String,
      default: () => {
        return ''
      }
    },
    time_comment: {
      type: String,
      default: () => {
        return ''
      }
    },
    checkError: {
      type: String,
      default: () => {
        return ''
      }
    },
    loadingPost: {
      type: Boolean,
      default: () => {
        return true
      }
    },
  },
  methods: {
    postComment() {
      console.log(this.loadingPost,'a')
      this.$emit('postComment');
    },
    postCommentPhuDao() {
      this.$emit('postCommentPhuDao');
    },
    changeComment(item, value) {
      this.$emit('changeComment', item, value);
    },
    close: function () {
      this.$emit('close');
    },
    changeClassify(item, value) {
      this.$emit('changeClassify',item , value);
    }
  },
}
</script>
<style>
.text-red {
  color: red;
}
.my-custom-scrollbar {
  position: relative;
  height: 400px;
  overflow: auto;
}
.table-wrapper-scroll-y {
  display: block;
}
</style>
