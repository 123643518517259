<template>
  <div>
    <table class="table table-bordered m-0">
      <thead>
      <tr>
        <th>Người thực hiện</th>
        <th>Thời gian mở slide</th>
        <th>Tên slide</th>
        <th>Site</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in dataTable" :key="index">
          <td>{{ item.user ? (item.user.id + 100000) + ' - ' + item.user.name : '' }}</td>
          <td>{{ $moment(item.created_at).format("H:mm:ss DD-MM-YYYY") }}</td>
          <td>{{ item.slide_name }}</td>
          <td>{{ item.site ? 'ADMIN' : 'TEACHER' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "historyOpenSlide",
  props: {
    dataTable: {
      type: Array,
      default: () => {
        return []
      }
    }
  }
}
</script>

<style scoped>

</style>