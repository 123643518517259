<template>
  <el-row>
    <el-row class="table-responsive-lg">
      <p class="d-flex justify-content-end" v-if="use_update">Cập nhật lúc {{use_update}}</p>
      <table class="table table-bordered m-0">
        <thead>
        <tr>
          <th> Họ và tên</th>
          <th> Mã học viên</th>
          <th> Số điện thoại</th>
          <th class="text-center"> Có mặt</th>
          <th class="text-center"> Vắng</th>
          <th class="text-center"> Có phép</th>
          <th class="text-center"> Lý do</th>
        </tr>
        </thead>
        <tbody>
        <AttendanceItem
            v-for="(item,index) in attendance_lesson" :key="index"
            :attendance="item"
            :currentDate="currentDate"
            :isExam="isExam"
            @changeAttendance="changeAttendance"
        ></AttendanceItem>
        </tbody>
      </table>
    </el-row>
    <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-default ml-2" @click="close">Đóng</button>
            <el-button class="ml-2"
                       type="primary"
                       :loading="isLoadingAttendance"
                       @click="attendancePostPhuDao"
                       :disabled="isDisable || disable || is_disable_button_attendance"
                       v-if="type_attendance"
                       v-show="is_show_attendance"
            >Điểm danh phụ đạo</el-button>
            <el-button class="ml-2"
                       type="primary"
                       v-show="is_show_attendance"
                       :disabled="is_disable_button_attendance"
                       :loading="isLoadingAttendance"
                       @click="attendancePost"
                       v-else-if="type_lesson !== 'test'"
            >Điểm danh</el-button> <!--:disabled="isExam"-->
        </span>
  </el-row>
</template>

<script>
import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import AttendanceItem from "./components/AttendanceItem";

const _ = deepdash(lodash);

export default {
  components: {AttendanceItem},
  props: {
    attendance_lesson: {
      type: Array,
      default: () => {
        return []
      }
    },
    type_attendance: {
      type: String,
      default: () => {
        return ''
      }
    },
    isExam: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    is_disable_button_attendance: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    is_show_attendance: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    currentDate: {
      type: String,
      default: () => {
        return new Date()
      }
    },
    disableButton: {
      type: Boolean,
      default: () => {
        return true
      }
    },
    use_update: {
      type: String,
      default: () => {
        return ''
      }
    },
    type_lesson: {
      type: String,
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {
      disable: false,
      isLoadingAttendance: false
    }
  },
  computed: {
    isDisable() {
      return new Date(this.currentDate) > new Date()
    }
  },
  watch: {
    disableButton() {
      this.disable = false;
    },
    attendance_lesson() {
      this.isLoadingAttendance = false;
    }
  },
  mounted() {
  },
  methods: {
    attendancePost() {
      this.$confirm('Chỉ có thể điểm danh một lần duy nhất. Bạn có chắc chắn muốn lưu kết quả điểm danh này?', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      }).then(() => {
        this.$emit('attendancePost');
        this.disable = true;
        this.isLoadingAttendance = true;
      });
    },
    attendancePostPhuDao() {
      this.$emit('attendancePostPhuDao');
      this.disable = true;
    },
    changeAttendance(type, course_student_id) {
      this.$emit('changeAttendance', type, course_student_id);
    },
    close: function () {
      this.$emit('close');
    },
  },
}
</script>
