<template>
  <tr>
    <td>{{ attendance.user_name }}</td>
    <td>{{ attendance.user_code }}</td>
    <td>{{ attendance.phone }}</td>
    <td class="text-center" v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null)">
      <el-checkbox label="1" v-model="attendance_A" @change="changeAttendance(1, attendance.id)"
                   class="checkbox-calendar"
                   key="1" :disabled="isDisable"></el-checkbox>
    </td>
    <td class="text-center" v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null )">
      <el-checkbox label="2" v-model="attendance_N" @change="changeAttendance(0, attendance.id)"
                   class="checkbox-calendar"
                   key="2" :disabled="isDisable"></el-checkbox>
    </td>
    <td class="text-center" v-if="attendance.status_class == studying || (attendance.attendance != 3 && attendance.attendance != null)">
      <el-checkbox label="3" v-model="attendance_P" @change="changeAttendance(2, attendance.id)"
                   class="checkbox-calendar"
                   key="3" :disabled="isDisable"></el-checkbox>
    </td>
    <td colspan="3" v-if="attendance.status_class == absent && attendance.attendance == null">
      <div class="bold">Học viên đã tạm dừng học lớp này</div>
    </td>
    <td colspan="3" v-if="attendance.status_class == stop_though && attendance.attendance == null">
      <div class="bold">Học viên đã tạm dừng học lớp này</div>
      <div>Học viên có thể tham gia buổi học này ? <button @click="continue_attendance(attendance)" class="bg-white border-0 text-info"><u>Điểm danh ngay</u></button></div>
    </td>
    <td>
      {{ attendance.reason }}
    </td>
  </tr>
</template>

<script>
import {STUDYING,ABSENT,STOP_THROUGH} from "@/core/services/store/classes/classes.module";
export default {
  name: "AttendanceItem",
  props: {
    attendance: {
      type: Object,
      default: () => {
        return {}
      }
    },
    currentDate: {
      type: String,
      default: () => { return '' }
    },
    isExam: {
      type: Boolean,
      default: () => {
        return false
      }
    }
  },
  data() {
    return {
      attendance_A: null,
      attendance_N: null,
      attendance_P: null,
      isDisable: false,
      studying: STUDYING,
      absent: ABSENT,
      stop_though: STOP_THROUGH,
      reason: ''
    }
  },
  mounted() {
    this.checkBox(this.attendance.attendance);
  },
  computed : {

  },
  watch: {
    attendance(value) {
      this.checkBox(value.attendance);
    },
    currentDate() {
      this.isDisable = new Date(this.currentDate) > new Date();
    }
  },
  methods: {
    changeAttendance(type, course_student_id) {
      switch (type) {
        case 1:
          this.attendance_P = false;
          this.attendance_N = false;
          break;
        case 0:
          this.attendance_P = false;
          this.attendance_A = false;
          break;
        default:
          this.attendance_A = false;
          this.attendance_N = false;
          break;
      }
      if (!this.attendance_P && !this.attendance_N && !this.attendance_A) {
        type = null;
      }
      this.$emit('changeAttendance', type, course_student_id)
    },
    checkBox(type) {
      switch (type) {
        case 1:
          this.attendance_A = true;
          this.attendance_P = false;
          this.attendance_N = false;
          this.isDisable = true;
          break;
        case 0:
          this.attendance_N = true;
          this.attendance_P = false;
          this.attendance_A = false;
          this.isDisable = true;
          break;
        case 2:
          this.attendance_P = true;
          this.attendance_A = false;
          this.attendance_N = false;
          this.isDisable = true;
          break;
        default:
          this.attendance_A = null;
          this.attendance_N = null;
          this.attendance_P = null;
          this.isDisable = false;

          break;
      }
    },
    continue_attendance(attendance){
      attendance.status_class = STUDYING;
    }
  }
}
</script>

<style>
.el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #409EFF !important;
  border-color: #409EFF !important;
}
</style>
