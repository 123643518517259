<template>
    <div class="schedule col-md-6 d-flex align-items-center justify-content-between shadow-1 overflow-auto" :class="comingLesson ? 'custom-padding' : ''" :style="isDisable ? 'background-color: rgb(180, 167, 214, 0.2) ;' : ''">
        <div class="coming d-flex align-items-center text-center" v-if="comingLesson">
          <div style="width: 100px">
           <b class="w-100">Lớp đang học tới</b>
          </div>
        </div>
        <div class="lesson-box mb-2 mt-3 w-50" :class="comingLesson ? 'ml-2' : ''">
            <div v-if="item.type !== 'tutoring'" class="">
                <h4 class="lesson"><b>Buổi {{ item.lesson }} <span v-if="item.type === 'test'" class="badge badge-success">Kiểm tra</span></b></h4>
                <p class="date-time m-0">Ngày {{ item.date_only }} <span
                        class="time">({{ item.start_time }} - {{ item.end_time }})</span></p>
            </div>
            <div v-else>
                <h4 class="lesson"><b> {{ item.name }} </b></h4>
                <p class="date-time m-0">Ngày {{ item.date }}</p>
            </div>
        </div>

        <!-- Action Buổi học -->
        <div class="lesson-action" v-if="item.type === 'homework' || item.type === ''">
            <el-dropdown trigger="click" @command="handleCommand">
              <el-button size="small" :style="isDisable ? 'background-color: rgb(180, 167, 214, 0.2) ;' : ''">
                Hành động<i class="el-icon-arrow-down el-icon--right"></i>
              </el-button>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="fal fa-calendar-check" command="attendance" v-if="!isBeforeDateTime(item.date)" disabled>
                  Điểm danh
                </el-dropdown-item>
                <el-dropdown-item icon="fal fa-calendar-check" command="attendance" v-else>
                  Điểm danh
                </el-dropdown-item>

                <el-dropdown-item @click="content" icon="fal fa-address-book" command="content">
                  Nội dung bài giảng
                </el-dropdown-item>
                <el-dropdown-item icon="fal fa-comment" command="comment">
                  Nhận xét học viên
                </el-dropdown-item>
                <el-dropdown-item v-if="item.course_schedule_type != 1" @click="giveGift" icon="far fa-gift" command="give-gift">
                  Tặng quà
                </el-dropdown-item>
                <el-dropdown-item v-if="item.course_schedule_type != 1" icon="fal fa-calendar-exclamation" command="view-homework">
                  Xem nội dung BTVN
                </el-dropdown-item>
                <el-dropdown-item v-if="item.course_schedule_type != 1" icon="fal fa-calendar-edit" command="mark-homework">
                  Chấm BTVN
                </el-dropdown-item>
                <el-dropdown-item v-if="dateOnlyNextLesson <= now_date" icon="el-icon-date" :disabled="true">
                  Đề xuất nghỉ
                </el-dropdown-item>
                <el-dropdown-item v-else-if="item.check_off === 0" icon="el-icon-date" command="cancelOffLesson">
                   Hủy đề xuất nghỉ
                </el-dropdown-item>
                <el-dropdown-item v-else-if="item.check_off === 2" icon="el-icon-date" command="cancelOffLesson">
                  <el-tooltip  class="item" effect="dark" content="Gửi đề xuất lại" placement="top-end">
                      <span>Bị từ chối</span>
                  </el-tooltip>
                </el-dropdown-item>
                <el-dropdown-item v-else icon="el-icon-date" command="offLesson">
                  Đề xuất nghỉ
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
        </div>
      <!-- Action Buổi học đầu và buổi cuối -->
      <div class="lesson-action" v-if="item.type === 'first' || item.type === 'end'">
        <el-dropdown trigger="click" @command="handleCommand">
          <el-button size="small" :style="isDisable ? 'background-color: rgb(180, 167, 214, 0.2) ;' : ''">
            Hành động<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item icon="fal fa-calendar-check" command="attendance" v-if="item.date >= now_date" disabled>
              Điểm danh
            </el-dropdown-item>
            <el-dropdown-item icon="fal fa-calendar-check" command="attendance" v-else>
              Điểm danh
            </el-dropdown-item>

            <el-dropdown-item @click="content" icon="fal fa-address-book" command="content">
              Nội dung bài giảng
            </el-dropdown-item>
            <el-dropdown-item icon="fal fa-comment" command="comment" v-if="item.course_schedule_type == 1">
              Nhận xét học viên
            </el-dropdown-item>
            <el-dropdown-item v-if="item.type !== 'first' && item.course_schedule_type != 1" icon="fal fa-calendar-exclamation" command="view-homework">
                  Xem nội dung BTVN
            </el-dropdown-item>
            <el-dropdown-item v-if="item.type !== 'first' && item.course_schedule_type != 1" icon="fal fa-calendar-edit" command="mark-homework">
                  Chấm BTVN 2
            </el-dropdown-item>
            <el-dropdown-item v-if="dateOnlyNextLesson <= now_date" icon="el-icon-date" :disabled="true">
              Đề xuất nghỉ
            </el-dropdown-item>
            <el-dropdown-item v-else-if="item.check_off === 0" icon="el-icon-date" command="cancelOffLesson">
              Hủy đề xuất nghỉ
            </el-dropdown-item>
            <el-dropdown-item v-else-if="item.check_off === 2" icon="el-icon-date" command="cancelOffLesson">
              <el-tooltip  class="item" effect="dark" content="Gửi đề xuất lại" placement="top-end">
                <span>Bị từ chối</span>
              </el-tooltip>
            </el-dropdown-item>
            <el-dropdown-item v-else icon="el-icon-date" command="offLesson">
              Đề xuất nghỉ
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>

        <!-- Action Buổi Phụ Đạo -->
        <div class="lesson-action" v-if="item.type === 'tutoring'">
          <el-dropdown trigger="click" @command="handleCommand">
            <el-button size="small" :style="isDisable ? 'background-color: rgb(180, 167, 214, 0.2) ;' : ''">
              Hành động<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="fal fa-calendar-check" command="openAttendancePhuDao" :disabled="true">
                Điểm danh
              </el-dropdown-item>
              <el-dropdown-item icon="fal fa-edit" command="edit">
                Sửa
              </el-dropdown-item>
              <el-dropdown-item icon="fal fa-comment" command="openCommentPhuDao">
                Nhận xét
              </el-dropdown-item>
              <el-dropdown-item icon="fal fa-trash" command="deletedPhuDao">
                Xóa
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>

        <!-- Action Kiểm tra -->
        <div class="lesson-action" v-if="item.type === 'test'">
          <el-dropdown trigger="click" @command="handleCommand">
            <el-button size="small" :style="isDisable ? 'background-color: rgb(180, 167, 214, 0.2) ;' : ''">
              Hành động<i class="el-icon-arrow-down el-icon--right"></i>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-date"
                                command="openAttendancePhuDao"
                                v-if="dateOnlyNextLesson <= now_date"
                                :disabled="true"
              >
                Đề xuất nghỉ
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-date" command="cancelOffLesson" v-else-if="item.check_off === 0">
                Hủy đề xuất nghỉ
              </el-dropdown-item>
              <el-dropdown-item icon="el-icon-date" command="offLesson" v-else-if="item.check_off === 2">
                <el-tooltip class="item" effect="dark" content="Gửi đề xuất lại" placement="top-end">
                     Bị từ chối
                </el-tooltip>
              </el-dropdown-item>
              <el-dropdown-item v-else icon="el-icon-date" command="offLesson">
                Đề xuất nghỉ
              </el-dropdown-item>
              <el-dropdown-item icon="fal fa-file-word" command="examResult">
                Kết quả
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
    </div>
</template>

<script>
    import lodash from 'lodash-es';
    import deepdash from 'deepdash-es';
    import { mapGetters } from "vuex";
    import moment from 'moment';
    import Swal from "sweetalert2";
    import { DELETE_TUTORING } from "@/core/services/store/classes/classes.module";
    import { ID_SCHEDULE_SCHOOL_REVIEW } from "../../../core/services/store/service.module";

    const _ = deepdash(lodash);

    export default {
        components: {},
        name: 'schedule-item',
        props: {
            item: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            items: {
                type: Array,
                default: () => {
                    return []
                }
            },
            title: {
                type: String,
                default: ''
            },
            index: {
                type: [Number, String],
                default: () => {
                    return ''
                }
            }
        },
        data() {
            return {
                now_date: moment().format("YYYY-MM-DD HH:mm:ss"),
                dateOnlyNextLesson : null,
                isDisable: false,
                comingLesson: false,
                greaterComingLesson : false,
                schedule_school_review: ID_SCHEDULE_SCHOOL_REVIEW
            }
        },
        computed: {
            ...mapGetters(['currentUser']),
        },
      watch: {
        item: {
          handler(){
            this.isDisable = this.isBeforeDateTime(this.item.date);
            if (this.item.type !== 'tutoring'){
              let matchLesson = this.items.findLast(item => this.isBeforeDateTime(item.date));
              let matchIndex =  this.items.indexOf(matchLesson);
              this.greaterComingLesson = this.item.lesson > matchIndex ? true : false;
              this.comingLesson = matchLesson && matchLesson.learning_schedule_id === this.item.learning_schedule_id && matchIndex !== this.items.length - 1;
            }
          },
          immediate: true,
          deep: true
        },
      },
        mounted() {
            this.checkNextLesson();
        },
        methods: {
            checkNextLesson() {
                this.items.map((value) => {
                    if (value.lesson == this.item.lesson + 1) {
                        this.dateOnlyNextLesson = value.date;
                    }
                })
                if (this.dateOnlyNextLesson) {
                    this.dateOnlyNextLesson = moment(this.dateOnlyNextLesson).format("YYYY-MM-DD HH:mm:ss");
                }
            },
            deletedPhuDao(id) {
                Swal.fire({
                    title: 'Bạn chắc chắn muốn xóa buổi phụ đạo này?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#7453a6',
                    cancelButtonColor: '#E5E5E5',
                    confirmButtonText: 'Tôi đồng ý',
                    cancelButtonText: 'Đóng'
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.$store.dispatch(DELETE_TUTORING, id).then(() => {
                            Swal.fire({
                                title: 'Xóa thành công',
                                text: "Buổi phụ đạo đã được xóa",
                                icon: 'success',
                                showCancelButton: false,
                                confirmButtonColor: '#7453a6',
                                confirmButtonText: 'Đóng',
                            });
                            this.$emit('tutoringSuccess');
                        }).catch((res) => {
                            this.$message({
                                message: 'Sever đang bận. Vui lòng thử lại sau!',
                                type: 'error'
                            });
                        })

                    }
                })
                // emit sang parent component để cập nhật lại danh sách lộ trình
            },
            handleCommand(command) {
              switch (command){
                case 'attendance' :
                  this.attendance();
                  break;
                  case 'cancelOffLesson' :
                  this.cancelOffLesson();
                  break;
                  case 'offLesson' :
                  this.offLesson();
                  break;
                  case 'content' :
                  this.content();
                  break;
                  case 'comment' :
                  this.comment();
                  break;
                  case 'view-homework' :
                  this.viewHomework();
                  break;
                  case 'mark-homework' :
                  this.markHomework();
                  break;
                  case 'openAttendancePhuDao' :
                  this.openAttendancePhuDao(this.item);
                  break;
                  case 'openCommentPhuDao' :
                  this.openCommentPhuDao(this.item);
                  break;
                  case 'edit' :
                  this.edit();
                  break;
                  case 'deletedPhuDao' :
                  this.deletedPhuDao(this.item.id);
                  case 'give-gift' :
                  this.giveGift(this.item.id);
                  break;
              }
            },
            attendance: function () {
                this.$emit('attendance', this.item , this.greaterComingLesson);
            },
            comment: function () {
                this.$emit('comment', this.item);
            },
            giveGift: function () {
                this.$emit('give-gift', this.item);
            },
            content: function () {
                this.$emit('content', this.item);
            },
            viewHomework: function () {
                this.$emit('view-homework', this.item);
            },
            markHomework: function () {
                this.$emit('mark-homework', this.item);
            },
            examResult: function (item) {
                this.$emit('examResult', item);
            },
            edit: function () {
                this.$emit('edit', this.item.id);
            },
            openAttendancePhuDao: function (item) {
                this.$emit('openAttendancePhuDao', item);
            },
            openCommentPhuDao: function (item) {
                this.$emit('openCommentPhuDao', item);
            },
            offLesson() {
                this.$emit('openOffLesson', {item: this.item, index: this.index});
            },
            cancelOffLesson() {
                this.$emit('cancelOffLesson', {item: this.item, index: this.index});
            },
            showNoficationDataClass() {
                var dialogNoficationDataClass = true;
                this.$emit('show-data-class', dialogNoficationDataClass)
            },
            isBeforeDateTime(date){
              let time = moment(date, 'YYYY-MM-DD HH:mm:ss');
              let now = moment();
              return time.isBefore(now);
            }
        },

    }
</script>
<style>
.el-dropdown-menu i{
  min-width: 20px;
}
</style>
