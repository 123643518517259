<template>
    <el-row>
        <el-row class="table-responsive-lg">
            <table class="table table-bordered m-0">
                <thead>
                <tr>
                    <th> Họ và tên </th>
                    <th> Mã học viên</th>
                    <th> Số điện thoại</th>
                    <th class="text-center"> Kết quả bài kiểm tra</th>
                </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in result_exams" :key="index">
                        <td>{{ item.name }}</td>
                        <td>{{ item.user_id + 100000 }}</td>
                        <td>{{ item.phone }}</td>
                        <td class="text-left" v-if="item.test">
                          <p>Điểm chấm tự động: <span class="bold">{{ item.test.auto_mark }}</span></p>
                          <p>Giáo viên chấm: <span class="bold">{{ item.test.gv_cham }}</span></p>
                        </td>
                      <td class="text-center" v-else>
                        <span class="badge badge-warning home-work-status">Chưa làm bài tập</span>
                      </td>
                    </tr>
                </tbody>
            </table>
        </el-row>
        <span class="dialog-footer mt-5 d-flex align-items-center justify-content-end">
            <button class="btn btn-outline-primary ml-2" @click="close" >Đóng</button>
        </span>
    </el-row>
</template>

<script>

export default {
    props: {
      result_exams: {
        type: Array,
        default: () => { return [] }
      }
    },
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
        close: function () {
            this.$emit('close');
        },
    },
}
</script>
